<script setup>

import { storeToRefs } from "pinia";
import { useAuthStore } from "~/stores/auth";
import { useCartStore } from '~/stores/cart';
const { $bootstrap, $modalsBus } = useNuxtApp();
const config = useRuntimeConfig();
var sidebar = null;
const formatters = useFormatters();
const route = useRoute();
const cartStore = useCartStore();
const authStore = useAuthStore();
const { promocode } = storeToRefs(cartStore);


watch(() => route.path, (to, from) => {
    if (to != from && sidebar != null) {
        sidebar.hide();
    }
}, { immediate: true, deep: true });

onMounted(() => {
    if (process.client) {
        sidebar = new $bootstrap.Offcanvas(document.getElementById('cart-sidebar'), {});

        $modalsBus.$on('sidebars.cart:open', () => {
            sidebar.show();
            cartStore.fetchUpsales();
            useInsider().page("Basket", "basket", {});
        });

        $modalsBus.$on('sidebars.cart:close', () => {
            sidebar.hide();
        });
    }
});

const submitPromocode = (submit) => {
    if (!authStore.user) {
        promocode.value = null;
        navigateTo('/auth/login');
        return;
    }

    if (submit == false) {
        promocode.value = null;
    };

    cartStore.fetch();
};

const onDelete = (cartProduct) => {
    cartStore.deleteFromCart(cartProduct);
};

const onDeleteCombo = (cartProduct) => {
    cartStore.deleteFromCart(cartProduct, false, true);
};

const onChangeQuantity = (cartProduct, value) => {
    if (value < 0) {
        cartStore.deleteFromCart(cartProduct, true);
    } else {
        cartStore.addQuantityToCart(cartProduct);
    }
};

const onAddUpsale = (item) => {
    cartStore.addToCart(item, {}, {}, {});
};

const service = computed(() => {
    if (!cartStore.cart.total_service) {
        return {
            free: true,
            text: formatters.fixedWhenNeeded(cartStore.cart.total_service, 2) + "₽"
        };
    }
    return {
        free: false,
        text: formatters.fixedWhenNeeded(cartStore.cart.total_service, 2) + "₽"
    };
});

</script>

<template>
    <div id="cart-sidebar" class="cart-sidebar offcanvas offcanvas-end" tabindex="-1" data-bs-backdrop="true">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExampleLabel">Корзина</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <custom-scrollbar :wrapperStyle="{ height: '100%' }" :class="{ 'empty': cartStore.isEmpty }"
            :style="{ height: '100%', paddingBottom: '80px' }" :simulateScroll="false" ref="scroll">
            <div class="cart-sidebar__body offcanvas-body">
                <CartLoader :loading="cartStore.loading" class="h-100">
                    <div v-if="!cartStore.isEmpty">
                        <div class="cart-sidebar__summary-container">
                            <div class="cart-rows">
                                <div v-for="item in cartStore.cart.cart_combos" class="cart-row" :key="item.id">
                                    <CartComboCard :cart-combo="item" @onDelete="onDeleteCombo" />
                                </div>
                                <div v-for="item in cartStore.cart.cart_products" class="cart-row" :key="item.id">
                                    <CartProductCard :cart-product="item" @onDelete="onDelete"
                                        @onChangeQuantity="onChangeQuantity" />
                                </div>
                            </div>

                            <div v-if="cartStore.cart != null">
                                <div class="cart-sidebar__promocode">
                                    <CartPromocode />
                                </div>
                            </div>

                            <div class="cart-sidebar__summary-container__price-rows">
                                <div class="price-row">
                                    <div class="name">Cумма заказа</div>
                                    <div class="value">
                                        {{ formatters.fixedWhenNeeded(cartStore.cart.total_no_discount, 2) }}₽
                                    </div>
                                </div>
                                <div class="price-row">
                                    <div class="name">Cкидка</div>
                                    <div class="value">
                                        {{ formatters.fixedWhenNeeded(cartStore.cart.total_no_discount - cartStore.cart.total, 2) }}₽
                                    </div>
                                </div>
                                <div class="price-row">
                                    <div class="name">Стоимость доставки</div>
                                    <div class="value">
                                        {{ service.text }}
                                    </div>
                                </div>
                                <div class="price-row">
                                    <div class="name">Сумма к оплате</div>
                                    <div class="value primary">
                                        {{ formatters.fixedWhenNeeded(cartStore.cart.total, 2) }}₽
                                    </div>
                                </div>
                                <div v-if="config.public.isDomino" class="price-row">
                                    <div class="name">Будет начислено баллов</div>
                                    <div class="value primary">
                                        {{ formatters.fixedWhenNeeded(cartStore.cart.loyalty?.full_cashback ?? 0, 2) }}
                                    </div>
                                </div>
                            </div>

                            <div v-if="false" class="cart-sidebar__summary-container-service-row">
                                <div class="d-flex">
                                    <div class="total me-auto">
                                        {{ service.text }}
                                    </div>
                                    <div class="name">Доставка</div>
                                </div>
                                <div v-if="cartStore.cart.delivery_diff != null" class="help">
                                    Осталось набрать на
                                    {{ formatters.fixedWhenNeeded(cartStore.cart.delivery_diff['amount']) }}₽ для
                                    доставки
                                    за
                                    {{ formatters.fixedWhenNeeded(cartStore.cart.delivery_diff['to']) }}₽
                                </div>
                            </div>
                            <div v-if="false" class="cart-sidebar__summary-container-alert">
                                <div class="icon"></div>
                                <div class="content">
                                    <div class="title">Много заказов 📦</div>
                                    <div class="text">
                                        Мы очень стараемся доставить Ваш заказ в срок, но заказов сегодня очень много..
                                        можем чуть чуть опоздать
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="cart-sidebar__summary-container-total-row">
                            <div class="total me-auto">
                                {{ formatters.fixedWhenNeeded(cartStore.cart.total, 2) }}₽
                            </div>
                            <DButton link="/checkout">Перейти к оформлению</DButton>
                        </div>
                        <div v-if="cartStore.cartBlockErrors.length > 0" class="cart-sidebar__summary-container-errors">
                            <div v-for="item in cartStore.cartBlockErrors" class="error-alert" role="alert">
                                <div class="error-alert__icon">
                                    <UiIconsInfoIcon />
                                </div>
                                <div class="error-alert__info">
                                    <div v-if="item.title" class="error-alert-title">{{ item.title }}</div>
                                    <div v-if="item.text" class="error-alert-text">{{ item.text }}</div>
                                </div>
                            </div>
                        </div>
                        <CartUpsaleCarousel v-if="cartStore.filteredUpsales" :upsales="cartStore.upsales"
                            @onAdd="onAddUpsale" />
                    </div>
                    <div v-else class="d-flex h-100">
                        <div class="m-auto text-muted text-center">
                            <img :src="config.public.isDomino ? '/images/empty-cart-image.svg' : '/images/empty-cart-image.png'"
                                alt="" width="200">
                            <div class="cart-sidebar__empty">
                                <div class="cart-sidebar__empty-header">Корзина пуста</div>
                                <div class="cart-sidebar__empty-text">Время выбрать любимую пиццу!</div>
                                <DButton :light="true" class="w-100 text-center mt-3" data-bs-dismiss="offcanvas"
                                    aria-label="Close">Вернуться к покупкам</DButton>
                            </div>
                        </div>
                    </div>
                </CartLoader>
            </div>
        </custom-scrollbar>
    </div>
</template>

<style lang="scss">
.cart-sidebar {
    background-color: #FCFCFC;
    border-radius: 30px 0px 0px 30px;
    overflow: hidden;

    .empty {

        .scrollbar__content,
        .scrollbar__scroller,
        .cart-sidebar__body {
            height: 100%;
        }

    }

    &.offcanvas.offcanvas-end {
        @media screen and (max-width: 576px) {
            width: 100%;
            border-radius: 0;
            border-left: none;
        }
    }


    .offcanvas-title {
        color: #000;
        font-size: 26px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
    }

    &__empty {
        margin-top: 10px;

        &-header {
            color: #000;
            text-align: center;
            font-size: 26px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
        }

        &-text {
            color: #000;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    &__promocode {
        display: flex;
        flex-direction: row;
        gap: 10px;
        width: 100%;

        margin-top: 20px;
        padding: 16px 12px;
    }

    &__body {
        padding: 10px;
    }

    &__summary-container {
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
        padding-bottom: 10px;

        &-alert {
            padding: 20px;
            background-color: #ececec;
            margin-top: 10px;
            border-left: 4px solid #a2d8f4;

            .content {
                .title {
                    font-weight: bold;
                    font-size: 14px;
                }

                .text {
                    line-height: 1.3;
                    font-size: 14px;
                    margin-top: 5px;
                    color: #000;
                }
            }
        }

        &__price-rows {
            display: flex;
            flex-direction: column;
            gap: 6px;
            margin-top: 6px;
            margin-left: 12px;
            margin-right: 12px;
            padding-top: 16px;
            border-top: 1px solid #e3e3e3;

            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            .price-row {
                display: flex;
                width: 100%;

                .value {
                    margin-left: auto;

                    &.primary {
                        color: $primary;

                        @if not $is-domino {
                            color: #000;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        &-total-row {
            display: flex;
            flex-direction: row;
            padding: 17px 0px;
            padding-right: 10px;

            .total {
                font-size: 26px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: if($is-domino, $primary, #000);
                margin-right: auto;
                margin-top: auto;
                margin-bottom: auto;
                padding-left: 10px;
            }
        }

        &-service-row {
            padding: 17px 10px;
            padding-bottom: 0;

            .total {
                font-size: 26px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: #000;
                margin-right: auto;
                margin-top: auto;
                margin-bottom: auto;
                padding-left: 10px;
            }

            .name {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .help {
                text-align: center;
                color: $primary;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        &-errors {
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .error-alert {
                display: flex;
                gap: 10px;
                margin-left: auto;
                margin-right: auto;

                &__icon {
                    font-size: 20px;
                    margin-left: auto;
                }

                &__info {
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    color: #DD052B;
                    margin-right: auto;
                    line-height: 1;
                }

                &>div {
                    margin-top: auto;
                    margin-bottom: auto;
                    text-align: center;

                    color: #DD052B;
                }
            }
        }
    }

    .cart-rows {
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
    }
}
</style>