<script setup>
import { useAuthStore } from '~/stores/auth';
const props = defineProps(['balanceLeft']);
const authStore = useAuthStore();
</script>
<template>
    <div class="profile-button">
        <DButton :light="true" :link="'/auth/profile'">
            <div class="profile-button__inner">
                <div class="profile-button__inner-icon">
                    <img src="/images/profile-icon-2.svg" width="16" />
                </div>
                <div class="profile-button__inner-text">
                    Профиль
                </div>
            </div>
        </DButton>
        <div v-if="authStore.loyalty != null" class="profile-button__balance" :class="{ left: balanceLeft === true }">
            <div><img src="/images/dcoin.png" height="24" width="24"></div>
            <div class="value">{{ authStore.loyalty?.balance }}</div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.profile-button {
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 40px;

    @if not $is-domino {
        @media screen and (min-width: 900px) {
            margin-left: 10px;
        }
    }

    @media screen and (max-width: 900px) {
        margin-right: 0;
    }

    &__inner {
        display: flex;
        align-items: center;

        @media screen and (max-width: 400px) {
            &-text {
                display: none;
            }
        }

        &-icon {
            margin-bottom: 4px;
        }

        &-text {
            margin-left: 10px;
        }
    }

    &__balance {
        position: absolute;
        top: -10px;
        left: calc(100% - 20px);
        display: flex;
        gap: 4px;
        padding: 2px 10px 2px 2px;
        background-color: #fff;
        box-shadow: 4px 5px 10px 0px rgba(0, 0, 0, 0.04);
        border-radius: 40px;
        pointer-events: none;

        font-size: 16px;
        font-weight: 900;

        div {
            margin-top: auto;
            margin-bottom: auto;
        }

        &.left {
            right: calc(100% + 10px);
            left: initial;
            top: 3px;
            box-shadow: none;

            @media screen and (max-width: 360px) {}
        }
    }


}
</style>