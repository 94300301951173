<script setup>
import { useAuthStore } from '~/stores/auth';
import { useCartStore } from '~/stores/cart';
const { $bootstrap } = useNuxtApp();

const authStore = useAuthStore();

var sidebar = null;

onMounted(() => {
    if (process.client) {
        sidebar = new $bootstrap.Offcanvas(document.getElementById('profile-sidebar'), {});
    }
});


const onTapLogout = () => {
    authStore.saveToken(null);
    authStore.user = null;
    authStore.address = null;
    authStore.addressSwitcherCourierSelected = true;
    const cookieToken = useCookie('auth_token', {
        sameSite: 'lax',
        maxAge: 60 * 60 * 24 * 365,
        expires: new Date(new Date().getDate() + 365)
    });
    cookieToken.value = undefined;

    useCartStore().fetch();

    sidebar.hide();
    useRouter().push("/");
};
</script>

<template>
    <div id="profile-sidebar" class="offcanvas offcanvas-end" tabindex="-1" data-bs-backdrop="true">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExampleLabel"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="profile-sidebar-body offcanvas-body">
            <div class="mt-3 mx-auto">
                <img class="logo" src="/images/footer-logo.png" alt="">
            </div>
            <div class="mt-3">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <NuxtLink @click="sidebar.hide()" class="nav-link" to="/auth/profile">Профиль</NuxtLink>
                    </li>
                    <li class="nav-item">
                        <NuxtLink @click="sidebar.hide()" class="nav-link" to="/orders">Мои заказы</NuxtLink>
                    </li>
                </ul>
            </div>
            <div class="mt-auto">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a @click.prevent="onTapLogout" class="nav-link" href="#">Выйти</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
#profile-sidebar {
    background-color: #fff;

    .btn-close {
        width: 20px;
        height: 20px;
        font-size: 16px;
        line-height: 0;
        cursor: pointer;

        transition: all 0.3s;
        text-align: center;

        color: #000;
        background-color: #fff;
        border-radius: 40px;

        opacity: 1;
    }

    .profile-sidebar-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        .logo {
            max-width: 50%;
            margin-bottom: 30px;
            margin-left: auto;
            margin-right: auto;
        }

        .nav-item {
            margin-left: auto;
            margin-right: auto;
            width: 100%;

            &:last-child {
                .nav-link {
                    border-bottom: none;
                }
            }

            .nav-link {
                // background-color: $primary;
                color: #000;
                border-bottom: 1px solid #dddddd;
                padding: 10px 20px;
                text-align: left;
                font-size: 14px;
                font-weight: 500;

                &.disabled {
                    color: #adadad;
                }


            }

            &:hover {
                .nav-link {
                    // background-color: darken($color: $primary, $amount: 3);

                    &.disabled {
                        // background-color: $primary;
                    }
                }
            }
        }
    }
}
</style>