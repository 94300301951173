<script setup>
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/stores/auth';

const router = useRouter();
const props = defineProps(['initialValue']);
const authStore = useAuthStore();
const { addressSwitcherCourierSelected } = storeToRefs(authStore);


const click = () => {
    addressSwitcherCourierSelected.value = !addressSwitcherCourierSelected.value;
    setTimeout(() => {
        router.push("/address");
    }, 200);

};
</script>

<template>
    <div @click="click" class="address-switcher">
        <div class="left-icon">
            <img src="/images/address-pickup-icon.png" width="30" alt="">
        </div>
        <div class="right-icon">
            <img src="/images/address-courier-icon.png" width="30" alt="">
        </div>
        <div class="indicator" :class="{ right: addressSwitcherCourierSelected }">
            <span>{{ addressSwitcherCourierSelected ? 'Доставка' : 'Самовывоз' }}</span>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.address-switcher {
    position: relative;
    display: flex;
    background-color: #F1F1F1;
    border-radius: 100px;
    height: 37px;
    width: 140px;
    cursor: pointer;
    user-select: none;

    @if not $is-domino {
        border: 1px solid #000;
    }

    @media screen and (max-width: 700px) {
        width: 125px;

        .left-icon {
            margin-left: 6px !important;
        }

        .right-icon {
            margin-right: 6px !important;
        }
    }

    * {
        user-select: none;
    }

    &:hover {
        .indicator {
            transition: all 0.4s;
        }
    }

    .indicator {
        position: absolute;
        display: flex;
        top: 4px;
        bottom: 4px;
        left: 4px;
        padding: 6px 16px 7px 16px;
        width: 92px;

        background-color: #fff;
        border-radius: 100px;

        font-size: 12px;
        text-align: center;
        opacity: 1;
        transition: all 0.4s;

        @if not $is-domino {
            border: 1px solid #000;
            line-height: 10px;
            top: 2px;
            bottom: 2px;

            span {
                padding-top: 1px;
            }
        }

        &.right {
            transform: translateX(40px);
        }

        @media screen and (max-width: 700px) {
            width: 84px;
            padding: 6px 8px 7px 8px;

            &.right {
                transform: translateX(33px);
            }
        }

        span {
            margin: auto;
        }
    }

    .left-icon {
        margin: auto;
        margin-left: 13px;
    }

    .right-icon {
        margin: auto;
        margin-right: 13px;
    }
}
</style>