<script setup>
import { useAuthStore } from '~/stores/auth';
const authStore = useAuthStore();
const config = useRuntimeConfig();
</script>

<template>
    <div class="header-address">
        <DLink class="address-block" to="/address">
            <div class="type">{{ authStore.addressSwitcherCourierSelected ? 'Адрес доставки' : 'Адрес самовывоза' }}
            </div>
            <div class="address">
                <span v-if="authStore.address && authStore.addressSwitcherSameSelected">
                    {{ authStore.address.address }}
                </span>
                <span v-else>
                    {{ authStore.addressSwitcherCourierSelected ? 'Выберите адрес доставки' : 'Выберите адрес самовывоза' }}
                </span>
            </div>
        </DLink>
        <HeaderAddressTypeSwitcher v-if="config.public.isDomino"
            :initialValue="!(authStore.address?.self_pickup ?? false)" />
    </div>
</template>

<style lang="scss" scoped>
.header-address {
    display: flex;
    gap: 10px;
    margin-right: 10px;

    .address-switcher {
        order: 2;
    }

    @media screen and (max-width: 900px) {
        margin-left: 0;
        margin-right: auto;

        .address-switcher {
            order: 1;
        }
    }
}

.address-block {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    order: 1;

    @media screen and (max-width: 900px) {
        order: 2;
    }

    .type {
        color: #646464;
        text-align: right;
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;
        margin-top: auto;

        @media screen and (max-width: 900px) {
            text-align: left;
        }
    }

    .address {
        margin-bottom: auto;
        color: #000;
        text-align: right;
        font-size: 14px;
        font-weight: 600;
        max-width: 230px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        @media screen and (max-width: 900px) {
            text-align: left;
        }

        @media screen and (max-width: 460px) {
            max-width: 200px;
            font-size: 14px;
        }

        @media screen and (max-width: 380px) {
            max-width: 180px;
            font-size: 12px;
        }

        @media screen and (max-width: 340px) {
            max-width: 145px;
            font-size: 12px;
        }
    }
}
</style>