<script setup>
import { DialogWrapper } from 'vue3-promise-dialog';
import { useCartStore } from '~/stores/cart';

const config = useRuntimeConfig();
const route = useRoute();

const cartStore = useCartStore();
const isMobile = ref(false);
const paddingBottom = computed(() => {
    if (cartStore.count > 0 && isMobile.value) {
        return '120px';
    }

    if (isMobile.value) {
        return '40px';
    }

    if (!config.public.isDomino) {
        return '40px';
    }

    return '0px';
});



onMounted(() => {
    if (process.client) {
        isMobile.value = window.innerWidth < 760;
    }
});

</script>
<template>
    <div>
        <div class="main-background"
            :class="{ 'with-image': !['/checkout', '/profile', '/oferta'].includes(route.path) && !['orders-id', 'profile', 'checkout', 'oferta', 'rules', 'policy'].includes(route.name) }">
        </div>
        <div style="min-height: 100vh; display: flex; flex-direction: column;">
            <div class="container-lg">
                <Sidebar />
                <CartSidebar />
            </div>
            <HeaderNavbar />
            <div>
                <slot />
            </div>
            <Footer style="margin-top: auto" :style="{ paddingBottom: paddingBottom }" />
            <ClientOnly>
                <DialogWrapper />
            </ClientOnly>
        </div>
    </div>
</template>